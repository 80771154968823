import { createFlagsmithInstance } from "flagsmith/isomorphic";
import type { IFlagsmith } from "flagsmith/types";
import { getDopplerSecrets } from "~/utils/secrets.server";

export async function getFlagsmith(identity: string | undefined): Promise<IFlagsmith> {
  const secrets = getDopplerSecrets({
    FLAGSMITH_ENV_ID: true,
  });

  const flagsmith = createFlagsmithInstance();

  await flagsmith.init({
    environmentID: secrets.FLAGSMITH_ENV_ID,
    identity,
  });

  return flagsmith;
}
