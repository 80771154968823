/* eslint-disable @typescript-eslint/no-unsafe-argument -- Typing Chakra theme properly is too difficult for the benefit -- https://github.com/chakra-ui/chakra-ui/issues/4573 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { extendTheme } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";
import "@fontsource/inter/400.css?__remix_sideEffect__";
import "@fontsource/inter/500.css?__remix_sideEffect__";
import "@fontsource/inter/600.css?__remix_sideEffect__";
import "@fontsource/inter/700.css?__remix_sideEffect__";

const interFontWeights = {
  // Make sure the font weights match the ones in the imported font files
  heavy: 700,
  label: 600,
  medium: 500,
  light: 400
};

const fontFamily =
"Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji";

const textVariants = {
  "h1-light": {
    fontFamily,
    fontSize: "36px",
    fontWeight: 400,
    lineHeight: "40px",
    letterSpacing: "-0.01em"
  },
  "h2-light": {
    fontFamily,
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "-0.01em"
  },
  "p1-light": {
    fontFamily,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "-0.01em"
  },
  "p2-light": {
    fontFamily,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "-0.01em"
  },
  "h1-heavy": {
    fontFamily,
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.01em"
  },
  "h2-heavy": {
    fontFamily,
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.01em"
  },
  "p1-heavy": {
    fontFamily,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.01em"
  },
  "p2-heavy": {
    fontFamily,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "-0.01em"
  }
};

// Customize Chakra theme
const customTheme = extendTheme({
  styles: {
    global: (props: any) => ({
      body: {
        color: mode("text.primary.black", "text.primary.white")(props)
      }
    })
  },
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: "indigo.500"
      }
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: "indigo.500"
        // errorBorderColor: "black",
      }
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: "indigo.600"
        }
      }
    },
    Modal: {
      baseStyle: {
        dialog: {
          padding: "2rem",
          gap: "1rem"
        },
        header: {
          padding: 0
        },
        body: {
          padding: 0
        },
        footer: {
          padding: 0
        },
        closeButton: {
          top: "1rem",
          right: "1rem"
        }
      }
    },
    Button: {
      defaultProps: {

        // colorScheme: "rose",
      } },
    Form: {
      baseStyle: (props: StyleFunctionProps) => ({
        helperText: {
          color: mode("text.primary.black", "text.primary.white")(props),
          margin: 0,
          fontSize: "0.875rem" /* 14px */,
          lineHeight: "1.25rem" /* 20px */
        }
      })
    },
    FormLabel: {
      baseStyle: {
        fontWeight: interFontWeights.label,
        margin: 0,
        fontSize: "1rem",
        lineHeight: "1rem",
        letterSpacing: "0.025em"
      }
    },
    Text: {
      variants: textVariants
    },
    Heading: {
      variants: textVariants
    }
  },
  fonts: {
    body: fontFamily
  },
  colors: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000000",
    white: "#FFFFFF",
    whiteAlpha: {
      50: "rgba(255, 255, 255, 0.04)",
      100: "rgba(255, 255, 255, 0.06)",
      200: "rgba(255, 255, 255, 0.08)",
      300: "rgba(255, 255, 255, 0.16)",
      400: "rgba(255, 255, 255, 0.24)",
      500: "rgba(255, 255, 255, 0.36)",
      600: "rgba(255, 255, 255, 0.48)",
      700: "rgba(255, 255, 255, 0.64)",
      800: "rgba(255, 255, 255, 0.80)",
      900: "rgba(255, 255, 255, 0.92)"
    },

    blackAlpha: {
      50: "rgba(0, 0, 0, 0.04)",
      100: "rgba(0, 0, 0, 0.06)",
      200: "rgba(0, 0, 0, 0.08)",
      300: "rgba(0, 0, 0, 0.16)",
      400: "rgba(0, 0, 0, 0.24)",
      500: "rgba(0, 0, 0, 0.36)",
      600: "rgba(0, 0, 0, 0.48)",
      700: "rgba(0, 0, 0, 0.64)",
      800: "rgba(0, 0, 0, 0.80)",
      900: "rgba(0, 0, 0, 0.92)"
    },

    gray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923"
    },
    // These colors below are from tailwind. I can't figure out how to import it dynamically from
    // tailwind.config.js because of some TypeScript typing issue.
    rose: {
      50: "#fff1f2",
      100: "#ffe4e6",
      200: "#fecdd3",
      300: "#fda4af",
      400: "#fb7185",
      500: "#f43f5e",
      600: "#e11d48",
      700: "#be123c",
      800: "#9f1239",
      900: "#881337"
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81"
    },
    stone: {
      50: "#fafaf9",
      100: "#f5f5f4",
      200: "#e7e5e4",
      300: "#d6d3d1",
      400: "#a8a29e",
      500: "#78716c",
      600: "#57534e",
      700: "#44403c",
      800: "#292524",
      900: "#1c1917"
    },
    text: {
      primary: {
        black: "#1e293b",
        white: "#f8fafc"
      },
      secondary: {
        black: "#64748b",
        white: "#94a3b8"
      }
    }
  },
  space: {
    xs: "0.25rem", // 4px
    sm: "0.5rem", // 8px
    md: "1rem", // 16px
    lg: "2rem", // 32px
    xl: "4rem", // 64px
    xxl: "8rem" // 128px
  }
});

// Wipe and extend theme components like this.
// customTheme.components.FormError.baseStyle = {};

export const chakraTheme = customTheme;
/* eslint-enable @typescript-eslint/no-unsafe-argument */
/* eslint-enable @typescript-eslint/no-explicit-any */