import { Box, Center, Text, Flex, Heading, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { PrimaryBtn } from "../core/primary-button";
import { useIntercom } from "~/providers/intercom";

export function ErrorBoundaryFallback(): JSX.Element {
  const { isBlocked: isIntercomBlocked, intercom } = useIntercom();

  return (
    <Box marginTop={4}>
      <Center>
        <Flex align="left" direction="column" gap="md" w="45ch">
          <Heading variant="h2-heavy">There&apos;s been a glitch</Heading>
          <Text className="text-left" variant="p1-light">
            Our team has been notified. If you need help now, chat with us.
          </Text>
          <FormControl isInvalid={isIntercomBlocked}>
            <PrimaryBtn
              disabled={isIntercomBlocked}
              eventName="Open Chat on Error Boundary"
              onClick={intercom?.showMessages}
              w="max-content"
            >
              Chat
            </PrimaryBtn>
            {isIntercomBlocked ? (
              <FormErrorMessage minWidth="0">
                The chat bubble was blocked by the browser. Try disabling your adblockers, or email
                us at support@usedouble.com
              </FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>
      </Center>
    </Box>
  );
}
