import type { ReactPortal } from "react";
import { useContext, useEffect } from "react";
import { withEmotionCache } from "@emotion/react";
import { ChakraProvider } from "@chakra-ui/react";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLoaderData,
  useLocation } from
"@remix-run/react";
// eslint-disable-next-line camelcase
import type { V2_MetaFunction, LinksFunction, LoaderArgs, TypedResponse } from "@remix-run/node";
import { json } from "@remix-run/node"; // Depends on the runtime you choose
import { ClerkApp, ClerkCatchBoundary } from "@clerk/remix";
import { getAuth, rootAuthLoader } from "@clerk/remix/ssr.server";
import { ClientOnly } from "remix-utils";
import { createPortal } from "react-dom";
import ProgressBar from "remix-progressbar";
import { withSentry, close as closeSentry } from "@sentry/remix";
import { cssBundleHref } from "@remix-run/css-bundle";
import { enablePatches, enableMapSet } from "immer";
import flagsmith from "flagsmith/isomorphic";
import { FlagsmithProvider } from "flagsmith/react";
import type { IState } from "flagsmith/types";
// import { HighlightInit } from "@highlight-run/remix/client";
import { ClientStyleContext } from "./context";
import styles from "./root.css";
import { pageview as gaPageview } from "~/ui/tracked/ga.client";
import { chakraTheme } from "~/utils/theme";
import NotFound from "~/routes/_public/$/_404";
import { ErrorBoundaryFallback } from "~/ui/error-boundary/fallback";
import { IntercomProvider } from "~/providers/intercom";
import { TrpcProvider } from "~/providers/trpc";
import { env } from "~/utils/frontend-env";
import { useTrack } from "~/ui/tracked/use-track";
import { getFlagsmith } from "~/server/flagsmith";
import { useIsImpersonatedSession } from "~/utils/use-is-impersonated-session";
import LogoWithWordmarkSvg from "~/ui/icons/logo-with-wordmark.svg";
// import { enableHighlight } from "~/constants";

// Enable Immer functions
enablePatches();
enableMapSet();

export async function loader(args: LoaderArgs): Promise<
  TypedResponse<{
    ENV: typeof env;
    flagsmithState: IState;
  }>>
{
  const { userId } = await getAuth(args);

  const flagsmithInstance = await getFlagsmith(userId ? userId : undefined);

  return rootAuthLoader(args, () => {
    return json({
      ENV: env,
      flagsmithState: flagsmithInstance.getState()
    });
  });
}

// eslint-disable-next-line camelcase
export function meta(): ReturnType<V2_MetaFunction> {
  return [
  { title: "Double | Find and convert leads with hyper-targeted messages using AI" },
  {
    name: "description",
    content: "Double | Find and convert leads with hyper-targeted messages using AI"
  },
  {
    property: "og:title",
    content: "Double | Find and convert leads with hyper-targeted messages using AI"
  },
  { property: "og:image", content: `https://www.usedouble.com${LogoWithWordmarkSvg}` },
  { name: "viewport", content: "width=device-width,initial-scale=1" }];

}

export const links: LinksFunction = () => {
  return [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  { rel: "preconnect", href: "https://fonts.gstatic.com" },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
  },
  { rel: "icon", href: "/favicon.ico" },
  { rel: "apple-touch-icon", href: "/favicon-196x196.png" },
  { rel: "stylesheet", href: styles },
  ...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : [])];

};

function TrackPageView({ children }: {children: JSX.Element;}): JSX.Element {
  const location = useLocation();
  const track = useTrack();

  useEffect(() => {
    track("Page View", { path: location.pathname });
  }, [location, track]);

  return children;
}

function Providers({ children }: {children: JSX.Element;}): JSX.Element {
  const { flagsmithState } = useLoaderData<typeof loader>();
  return (
    <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
      <ChakraProvider theme={chakraTheme}>
        <IntercomProvider>
          <TrpcProvider>
            <TrackPageView>{children}</TrackPageView>
          </TrpcProvider>
        </IntercomProvider>
      </ChakraProvider>
    </FlagsmithProvider>);

}

export const Head = withEmotionCache((_props, emotionCache) => {
  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  useEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any -- Copied from https://chakra-ui.com/getting-started/remix-guide
      (emotionCache.sheet as any)._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData?.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Copied from https://chakra-ui.com/getting-started/remix-guide#2-provider-setup
  }, []);

  return (
    <>
      <Meta />
      <Links />
    </>);

});

interface DocumentProps {
  children: JSX.Element;
}

function Document({ children }: DocumentProps): JSX.Element {
  return (
    <>
      {/* {enableHighlight ? (
         <HighlightInit
           backendUrl="https://highlight.usedouble.com/"
           environment={env.REACT_APP_ENV}
           networkRecording={{ enabled: true, recordHeadersAndBody: true }}
           projectId="5g5vvpen"
           tracingOrigins={["localhost", /^\//]}
           version={env.VERCEL_GIT_COMMIT_SHA}
         />
        ) : null} */}
      <ClientOnly>{(): ReactPortal => createPortal(<Head />, document.head)}</ClientOnly>
      <Providers>{children}</Providers>
      <ScrollRestoration />
      <Scripts />
      <LiveReload />
    </>);

}

function Root(): JSX.Element {
  const data = (useLoaderData<typeof loader>() as {ENV: typeof env;});

  const location = useLocation();
  const isImpersonatedSession = useIsImpersonatedSession();

  useEffect(() => {
    if (data.ENV.GA_TRACKING_ID.length && !isImpersonatedSession) {
      gaPageview(location.pathname, data.ENV.GA_TRACKING_ID);
    }
  }, [location, data.ENV.GA_TRACKING_ID, isImpersonatedSession]);

  useEffect(() => {
    if (isImpersonatedSession) {
      closeSentry(0).then(
        () => console.log("Sentry is closed"),
        (error) => console.error(error)
      );
    }
  }, [location, isImpersonatedSession]);

  return (
    <Document>
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`
          }} />

        {data.ENV.REACT_APP_ENV === "prd" && data.ENV.GA_TRACKING_ID ?
        <>
            <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${data.ENV.GA_TRACKING_ID}`} />

            <script
            async
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${data.ENV.GA_TRACKING_ID}', {
                  page_path: window.location.pathname,
                });
              `
            }}
            id="gtag-init" />

          </> :
        null}
        <ProgressBar color="#fb7185" showSpinner={false} />
        <Outlet />
      </>
    </Document>);

}

function CatchBoundaryImpl(): JSX.Element {
  const caught = useCatch();
  return <Document>{caught.status === 404 ? <NotFound /> : <ErrorBoundaryFallback />}</Document>;
}

function ErrorBoundaryImpl(): JSX.Element {
  return (
    <Document>
      <ErrorBoundaryFallback />
    </Document>);

}

export const ErrorBoundary = process.env.NODE_ENV === "development" ? undefined : ErrorBoundaryImpl;

// Workaround for https://slack.com/archives/C04NABBV2AF/p1675755297208059
function PassthroughCatchBoundary(): JSX.Element {
  const caught = useCatch();
  if (caught.status !== 200) {
    throw caught;
  }

  return <>We caught a 200 response that was thrown, which is unexpected.</>;
}
const maybeCatchBoundary =
process.env.NODE_ENV === "development" ? PassthroughCatchBoundary : CatchBoundaryImpl;
export const CatchBoundary = ClerkCatchBoundary(maybeCatchBoundary);

export default withSentry(ClerkApp(Root));