// from: https://github.com/remix-run/examples/blob/main/google-analytics/app/utils/gtags.client.ts

declare global {
  interface Window {
    gtag: (option: string, gaTrackingId: string, options: { [key: string]: unknown }) => void;
  }
}

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
export const pageview = (url: string, trackingId: string): void => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Copied from examples.
  if (!window.gtag) {
    console.warn(
      "window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet."
    );
    return;
  }
  window.gtag("config", trackingId, {
    page_path: url,
  });
};

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const event = ({ action, category, label, value }: { [key: string]: string }): void => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Copied from examples.
  if (!window.gtag) {
    console.warn(
      "window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet."
    );
    return;
  }
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
  });
};
